import clsx from "classnames"
import { ContentSummaryTag } from "common/components/ContentSummaryTag"
import { Heading } from "common/components/Heading"
import { ImgixImage } from "common/components/ImgixImage"
import { closestCrop } from "modules/editor/utils/closestCrop"
import { ImageAspectRatio } from "modules/editor/types"
import { Image, Maybe } from "common/types/graphql"
import type { ContentSummaryVariantProps } from "common/components/ContentSummary/types"

interface BaseSummaryImageProps {
  image?: Maybe<Partial<Image>>
  title?: string
  imageSizes?: string
  imageAspectRatio?: ImageAspectRatio
  squareClassName?: string
  wideClassName?: string
}

/**
 * `BaseSummaryImage` is a functional component that renders an image with different crops based on the screen size.
 * The component uses the `closestCrop` function to determine the best crop for the image based on the provided aspect ratio.
 * If the image source is undefined, the component will return null.
 * For medium and larger screens, a widescreen crop is used, and for small screens, a square crop is used.
 */
export const BaseSummaryImage = ({
  image,
  imageSizes,
  wideClassName = "",
  squareClassName = "",
}: BaseSummaryImageProps) => {
  if (image?.src === undefined) {
    return null
  }

  const squareCrop = closestCrop(ImageAspectRatio.Square, image.crops || [])
  const wideCrop = closestCrop(ImageAspectRatio.Widescreen, image.crops || [])
  const imageProps = {
    ...image,
    src: image.src,
    alt: image.alt,
    sizes: imageSizes,
  }

  return (
    <>
      <div className={clsx("relative col-span-3 hidden aspect-video sm:block", wideClassName)}>
        <ImgixImage {...imageProps} crop={wideCrop} />
      </div>
      <div className={clsx("relative col-span-1 aspect-square sm:hidden", squareClassName)}>
        <ImgixImage {...imageProps} crop={squareCrop} />
      </div>
    </>
  )
}

export const BaseTitleBylineTag = ({
  title,
  byline,
  hideByline,
  contentType,
  tagLabel,
  tagStyle,
}: ContentSummaryVariantProps) => (
  <section className="order-first col-span-2 sm:order-last sm:col-span-3">
    <span className="group-hover:text-indigo-600 group-hover:underline">
      <Heading level={5}>
        <span dangerouslySetInnerHTML={{ __html: title || "" }} />
      </Heading>
    </span>
    {byline && !hideByline && <p className="mt-1 line-clamp-4 text-3xs text-gray-600">{byline}</p>}
    <div className="mt-2 empty:mt-0">
      <ContentSummaryTag contentType={contentType} tagLabel={tagLabel} tagStyle={tagStyle} />
    </div>
  </section>
)

/**
 * The `Base` component is a variant of the `ContentSummary` component.
 * The summary image can use two different crops due to its aspect ratio per device: square or widescreen.
 * The crop aspect ratio depends on the screen size, where smaller devices will use the square aspect ratio.
 */
export const Base = ({
  url,
  imageSizes,
  image,
  anchorTarget,
  className,
  ...props
}: ContentSummaryVariantProps) => (
  <a
    href={url}
    target={anchorTarget}
    className={clsx("group grid h-fit w-full grid-cols-3 gap-x-4 gap-y-2 2xl:gap-x-8", className)}
  >
    <BaseTitleBylineTag {...props} />
    <BaseSummaryImage image={image} imageSizes={imageSizes} />
  </a>
)
