import { ContentSummaryBlock, ContentTypeEnum, Maybe, TagStyle } from "common/types/graphql"
import { AudioTag } from "./variants/AudioTag"
import { VideoTag } from "./variants/VideoTag"
import { Tag } from "common/components/Tag"

export const ContentSummaryTag = (
  props: Pick<ContentSummaryBlock, "contentType" | "tagLabel" | "tagStyle">,
): Maybe<JSX.Element> => {
  const { contentType, tagLabel, tagStyle } = props
  if (tagStyle === TagStyle.Video) {
    return <VideoTag />
  } else if (tagStyle === TagStyle.Custom && tagLabel) {
    return <Tag>{tagLabel}</Tag>
  } else if (contentType === ContentTypeEnum.Audio || tagStyle === TagStyle.Audio) {
    return <AudioTag />
  }
  return null
}
