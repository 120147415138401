import clsx from "classnames"
import type { ContentSummaryVariantProps } from "common/components/ContentSummary/types"
import {
  BaseSummaryImage,
  BaseTitleBylineTag,
} from "common/components/ContentSummary/variants/Base"

export const DisappearingThumbnail = ({
  url,
  imageSizes,
  image,
  anchorTarget,
  className,
  ...props
}: ContentSummaryVariantProps) => (
  <a
    href={url}
    target={anchorTarget}
    className={clsx("group grid w-full grid-cols-3 gap-x-4 2xl:grid-cols-5 2xl:gap-x-8", className)}
  >
    <BaseTitleBylineTag {...props} />
    <BaseSummaryImage
      image={image}
      imageSizes={imageSizes}
      wideClassName="sm:hidden 2xl:block 2xl:col-span-2"
    />
  </a>
)
