import type { Image, Maybe } from "common/types/graphql"
import { ContentTypeEnum, TagStyle } from "common/types/graphql"
import type { ImageAspectRatio } from "modules/editor/types"

export enum ContentSummaryVariant {
  Base = "BASE",
  BaseWide = "BASE_WIDE",
  Hero = "HERO",
  DisappearingThumbnail = "DISAPPEARING_THUMBNAIL",
}

export interface ContentSummaryProps {
  title?: string
  customTitle?: Maybe<string>
  url?: string
  byline?: Maybe<string>
  hideByline?: Maybe<boolean>
  body?: Maybe<string>
  contentType?: Maybe<ContentTypeEnum>
  image?: Maybe<Partial<Image>>
  imageSizes?: string
  imageAspectRatio?: ImageAspectRatio
  imageFit?: "cover" | "contain"
  tagStyle?: Maybe<TagStyle>
  tagLabel?: Maybe<string>
  variant?: ContentSummaryVariant
  /**
   * Optional parameter to define the target attribute of the anchor tag, only allowing the "_blank" value.
   * When not defined, the anchor tag will not have a target attribute.
   */
  anchorTarget?: "_blank"
  /**
   * Optional parameter to add custom classes to underlying content summary variants.
   */
  className?: string
}

// ContentSummary is responsible for performing title selection
// so that variants don't need to duplicate this logic
export type ContentSummaryVariantProps = Omit<ContentSummaryProps, "customTitle">
