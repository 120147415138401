import clsx from "classnames"
import { ContentSummaryTag } from "common/components/ContentSummaryTag"
import { Heading } from "common/components/Heading"
import { ImgixImage } from "common/components/ImgixImage"
import { closestCrop } from "modules/editor/utils/closestCrop"
import { ImageAspectRatio } from "modules/editor/types"
import type { ContentSummaryVariantProps } from "../types"
import { Image, Maybe } from "common/types/graphql"

interface BaseWideSummaryImageProps {
  image?: Maybe<Partial<Image>>
  title?: string
  imageSizes?: string
  imageAspectRatio?: ImageAspectRatio
  imageFit?: "cover" | "contain"
}

/**
 * `BaseWideSummaryImage` is a functional component that renders an image with different crops based on the screen size.
 * The component uses the `closestCrop` function to determine the best crop for the image based on the provided aspect ratio.
 * If the image source is undefined, the component will return null.
 * For larger screens (2xl), a widescreen crop is used, and for small screens, a square crop is used.
 */
export const BaseWideSummaryImage = ({
  image,
  imageSizes,
  imageFit,
  imageAspectRatio,
}: BaseWideSummaryImageProps) => {
  if (image?.src === undefined) {
    return null
  }

  const squareCrop = closestCrop(ImageAspectRatio.Square, image.crops || [])
  const wideCrop = closestCrop(ImageAspectRatio.Widescreen, image.crops || [])
  const imageProps = {
    ...image,
    src: image.src,
    alt: image.alt,
    sizes: imageSizes,
    objectFit: imageFit,
  }

  // if an aspect ratio is given, use it
  if (imageAspectRatio) {
    const chosenCrop = imageAspectRatio === ImageAspectRatio.Square ? squareCrop : wideCrop
    return (
      <div
        className={clsx("relative col-span-1 sm:col-span-2", {
          "aspect-square": imageAspectRatio === ImageAspectRatio.Square,
          "aspect-video": imageAspectRatio !== ImageAspectRatio.Square,
        })}
      >
        <ImgixImage {...imageProps} crop={chosenCrop} />
      </div>
    )
  }

  // Automatically swap best crop based on screen size
  return (
    <>
      <div className="relative col-span-1 hidden aspect-video sm:col-span-2 2xl:block">
        <ImgixImage {...imageProps} crop={wideCrop} />
      </div>
      <div className="relative col-span-1 aspect-square sm:col-span-2 2xl:hidden">
        <ImgixImage {...imageProps} crop={squareCrop} />
      </div>
    </>
  )
}
export const BaseWide = ({
  title,
  url,
  byline,
  hideByline,
  contentType,
  imageSizes,
  image,
  imageFit,
  imageAspectRatio,
  tagLabel,
  tagStyle,
  anchorTarget,
  className,
}: ContentSummaryVariantProps) => {
  return (
    <a
      href={url}
      target={anchorTarget}
      className={clsx(
        "group grid h-fit w-full grid-cols-3 gap-x-4 gap-y-2 sm:grid-cols-6 2xl:gap-x-8",
        className,
      )}
    >
      <section className="col-span-2 gap-2 sm:col-span-4">
        <span className="group-hover:text-indigo-600 group-hover:underline">
          <Heading level={5}>
            <span dangerouslySetInnerHTML={{ __html: title || "" }} />
          </Heading>
        </span>
        {<ContentSummaryTag contentType={contentType} tagLabel={tagLabel} tagStyle={tagStyle} />}
        {byline && !hideByline && <p className="line-clamp-3 text-3xs text-gray-600">{byline}</p>}
      </section>

      <BaseWideSummaryImage
        image={image}
        imageSizes={imageSizes}
        imageFit={imageFit}
        imageAspectRatio={imageAspectRatio}
      />
    </a>
  )
}
